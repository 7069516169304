export default class HangmanCanvas {
    constructor(canvasEl) {
        this.canvasEl = canvasEl
        this.ctx = this.canvasEl.getContext('2d');

        this.drawArray = [
            this.rightLeg.bind(this), this.leftLeg.bind(this),
            this.rightArm.bind(this), this.leftArm.bind(this),
            this.torso.bind(this), this.head.bind(this),
            this.frame4.bind(this), this.frame3.bind(this), this.frame2.bind(this), this.frame1.bind(this)
        ];
        this.start();
    }

    next(index) {
        if (this.drawArray[index]) {
            this.drawArray[index]();
        }
    }

    start() {
        this.ctx.beginPath();
        this.ctx.strokeStyle = "#fff";
        this.ctx.lineWidth = 2;
    }

    reset() {
        this.ctx.clearRect(0, 0, 400, 400);
    }

    head() {
        this.ctx.beginPath();
        this.ctx.arc(60, 25, 10, 0, Math.PI*2, true);
        this.ctx.stroke();
    }

    draw($pathFromx, $pathFromy, $pathTox, $pathToy) {
        this.ctx.moveTo($pathFromx, $pathFromy);
        this.ctx.lineTo($pathTox, $pathToy);
        this.ctx.stroke();
    }

    frame1() {
        this.draw(0, 150, 150, 150);
    }
    
    frame2() {
        this.draw(10, 0, 10, 600);
    }
    
    frame3 () {
        this.draw(0, 5, 70, 5);
    }
    
    frame4() {
        this.draw(60, 5, 60, 15);
    }
    
    torso() {
        this.draw(60, 36, 60, 70);
    }
    
    rightArm() {
        this.draw(60, 46, 100, 50);
    }
    
    leftArm() {
        this.draw(60, 46, 20, 50);
    }
    
    rightLeg() {
        this.draw(60, 70, 100, 100);
    }
    
    leftLeg() {
        this.draw(60, 70, 20, 100);
    }
}
